import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from '@app/user/services/user.service';
import { BackgroundGraphicsComponent } from '@shared-components/background-graphics/background-graphics.component';
import { BackgroundOptions } from '@shared-interfaces/background-options.interface';
import { startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-background-graphics-manager',
  imports: [BackgroundGraphicsComponent],
  templateUrl: './background-graphics-manager.component.html',
  styleUrls: ['./background-graphics-manager.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackgroundGraphicsManagerComponent {
  protected userService = inject(UserService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  public inContainer = input<boolean>(false);

  private routerDataBackground = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['background']) {
            return child.snapshot.data['background'] as BackgroundOptions;
          } else {
            return {} as BackgroundOptions;
          }
        }

        return {} as BackgroundOptions;
      }),
      // Make sure graphics are shown even though no routing has taken place by firing an initial empty object
      startWith({} as BackgroundOptions),
    ),
    { requireSync: true },
  );

  protected readonly showPattern = computed(() => this.routerDataBackground().showPattern ?? true);
  protected readonly showGraphics = computed(() => this.routerDataBackground().showGraphics ?? true);
  protected readonly disableAnimations = computed(() => this.routerDataBackground().disableAnimations ?? false);
  protected readonly backgroundColor = computed(() => this.routerDataBackground().color);
  protected readonly backgroundImageUrl = computed(() => this.routerDataBackground().imageUrl);

  protected get isWinter(): boolean {
    return [0, 1, 11].includes(new Date().getMonth());
  }
}
