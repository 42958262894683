export type linkType = 'link-external' | 'link-content' | 'internal-module' | 'dropdown' | 'embed-video';

export type targetType = '_self' | '_blank' | '_parent' | '_top' | undefined;

export type menuType = 'primary' | 'mega' | 'level' | 'submenu';

export type containerWidthType = 'fullWidth' | 'full-width' | 'large' | 'medium' | 'small' | undefined;

export type alignType = 'left' | 'center' | 'right' | undefined;

export type userType = 'notLoggedIn' | 'teacher' | 'student';

export type visibilityType =
  | 'All users'
  | 'Only logged in users'
  | 'Only logged in students'
  | 'Only logged in teachers'
  | 'Not logged in users'
  | undefined
  | null;

const ALL_PAGE_TYPES = [
  'frontpage',
  'contentpage',
  'lounge-team-admin',
  'mf20-insight',
  'mf20-training',
  'mf20-assignments',
  'mf20-assignments-managed',
  'mf20-dashboard-teacher',
  'mf20-dashboard-student',
  'mf20-find-content',
  'mf20-achievements-page',
  'mf20-evaluation',
  'mf20-battle',
  'error',
  'error-503',
  // undefined,
] as const;
type pageTypeTuple = typeof ALL_PAGE_TYPES;
export type pageType = pageTypeTuple[number];
export function isPageType(value: string): value is pageType {
  return ALL_PAGE_TYPES.includes(value as pageType);
}
