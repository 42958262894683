<app-background-graphics
  [isTeacher]="userService.isTeacher()"
  [isWinter]="isWinter"
  [hasLoadedUserData]="userService.hasLoadedUserData()"
  [backgroundColor]="backgroundColor()"
  [backgroundImageUrl]="backgroundImageUrl()"
  [showGraphics]="userService.hasLoadedUserData() && showGraphics()"
  [showPattern]="userService.hasLoadedUserData() && showPattern()"
  [inContainer]="inContainer()"
  [disableAnimations]="disableAnimations()"
></app-background-graphics>
