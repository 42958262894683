import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { CartComponent } from '@app/cart/components/cart/cart.component';
import { CartService } from '@app/cart/services/cart.service';
import { AzureAppConfigurationService } from '@app/core/services/azure-app-configuration.service';
import { CoreService } from '@app/core/services/core.service';
import { HeaderConfig } from '@app/header/interfaces/header-config.interface';
import { NavbarMapper } from '@app/page/mapper/navbar.mapper';
import { NavbarService } from '@app/page/services/navbar.service';
import { TeamSelectorDropdownComponent } from '@app/teams/components/team-selector-dropdown/team-selector-dropdown.component';
import { TeamsService } from '@app/teams/services/teams.service';
import { TrackingFunctionsService } from '@app/tracking/services/tracking-functions.service';
import { UserService } from '@app/user/services/user.service';
import { TranslatePipe } from '@ngx-translate/core';
import { ButtonComponent, ButtonVariant } from '@shared-components/ui/button/button.component';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { PanelButtonComponent } from '@shared-components/ui/panel-button/panel-button.component';
import { UserComponent } from '@shared-components/user/user.component';
import { Icons } from '@shared-data/icons';
import { DropDownButtonType } from '@shared-enums/drop-down-button-type.enum';
import { IconSize } from '@shared-enums/icon-size.enum';
import { DropdownComponent } from '@shared-modules/dropdown/components/dropdown/dropdown.component';
import { DropdownModule } from '@shared-modules/dropdown/dropdown.module';
import { DropdownService } from '@shared-modules/dropdown/services/dropdown.service';
import { filter, Subject, Subscription } from 'rxjs';

import { MegaMenuComponent } from '../mega-menu/mega-menu.component';
import { NavigationType, NavMenuBaseComponent } from '../nav-menu-base/nav-menu-base.component';

type dropdownType = 'cart' | 'user';

@Component({
  imports: [
    NgIf,
    AsyncPipe,
    IconButtonComponent,
    ButtonComponent,
    UserComponent,
    TeamSelectorDropdownComponent,
    TranslatePipe,
    NavMenuBaseComponent,
    MegaMenuComponent,
    CartComponent,
    DropdownComponent,
    PanelButtonComponent,
    RouterLink,
    DropdownModule,
    IconComponent,
  ],
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnDestroy {
  public NavigationType = NavigationType;
  public Icons = Icons;
  public DropDownButtonType = DropDownButtonType;
  public ButtonVariant = ButtonVariant;
  public userMenuIcon = Icons.USER;
  public cartMenuIcon = Icons.CART;
  private routerSub = new Subscription();
  public basketButtonDisabled$ = new Subject<boolean>();
  private currentUrl: string = '';

  @ViewChild('megaMenuDropdown') megaMenuDropdown?: PanelButtonComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public navbarService: NavbarService,
    private dropDownService: DropdownService,
    public coreService: CoreService,
    public userService: UserService,
    public cartService: CartService,
    public azureAppConfigurationService: AzureAppConfigurationService,
    public teamsService: TeamsService,
    private navbarMapper: NavbarMapper,
    private trackingFunctionsService: TrackingFunctionsService,
  ) {
    this.routerSub.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {
          if (e.url !== '/contentpage') {
            this.currentUrl = e.url;
            this.navbarService.setCurrentUrl(e.url);
          }
          const headerConfigData = this.extractHeaderConfigData(this.activatedRoute);
          this.basketButtonDisabled$.next(headerConfigData?.basketButtonDisabled);

          // Hide the mega menu, assuming the navigation end event is a result of the user clicking a link in the menu
          this.megaMenuDropdown?.toggleDropDown(false);
        }),
    );
  }
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

  private extractHeaderConfigData(route: ActivatedRoute): HeaderConfig {
    let headerConfigData = route.snapshot.data.headerConfig;

    route.children.forEach((childRoute) => {
      headerConfigData = { ...headerConfigData, ...this.extractHeaderConfigData(childRoute) };
    });

    return headerConfigData;
  }

  toggleOpen(type: dropdownType): Function {
    return () => {
      switch (type) {
        case 'cart':
          if (this.cartMenuIcon === Icons.CLOSE) {
            this.cartMenuIcon = Icons.CART;
          } else {
            this.trackingFunctionsService.trackCartOpenBasket();
            this.cartMenuIcon = Icons.CLOSE;
          }

          break;
        case 'user':
          if (this.userMenuIcon === Icons.CLOSE) {
            this.userMenuIcon = Icons.USER;
          } else {
            this.userMenuIcon = Icons.CLOSE;
          }

          break;
      }
    };
  }

  onCartDismissClick(): void {
    this.dropDownService.closeDropdown();
  }
  onLogin(): void {
    this.userService.userLogin();
  }
  isLinkActive(url: string): boolean {
    return this.navbarMapper.isLinkActive({ items: [], title: '', url: url, type: '' }, this.currentUrl);
  }

  protected readonly IconSize = IconSize;
}
