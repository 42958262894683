import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { UserService } from '@app/user/services/user.service';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { TranslatePipe } from '@ngx-translate/core';
import { ButtonComponent, ButtonSize } from '@shared-components/ui/button/button.component';

import { PageTypeLinkPipe } from '../../page/pipes/page-type-link.pipe';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  imports: [NgIf, AsyncPipe, TranslatePipe, ButtonComponent, PageTypeLinkPipe],
})
export class UserComponent {
  public user$ = this.userService.user$;
  public isLoggedIn$ = this.userService.isLoggedIn$;
  public ButtonSize = ButtonSize;

  constructor(
    public userService: UserService,
    public azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  onLogin(): void {
    this.userService.userLogin();
  }

  onLogout(): void {
    this.userService.userLogOut();
  }
}
