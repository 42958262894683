import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ToastMessageInstance } from '@app/toast/interfaces/toast-message-instance.interface';
import { ToastService } from '@app/toast/services/toast.service';
import { ToastEventType } from '@app/toast/toast-types';
import { TranslatePipe } from '@ngx-translate/core';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';

import { BadgeComponent } from '../../../achievements-page/components/badge/badge.component';

@Component({
  selector: 'app-toast-message[message]',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, IconComponent, IconButtonComponent, TranslatePipe, BadgeComponent],
})
export class ToastMessageComponent {
  @Input() message!: ToastMessageInstance;

  @HostBinding('style.--real-toast-height') get styleRealToastHeight(): string {
    return this.message.type === ToastEventType.SINGLE_BADGE ? `${IconSize.XXLARGE}px` : '';
  }

  @HostBinding('attr.data-toast-type') get dataToastType(): string {
    return this.message.type;
  }

  Icons = Icons;
  IconSize = IconSize;
  ToastEventType = ToastEventType;

  constructor(private toastService: ToastService) {}

  public get getIconColor(): string {
    if (!this.message.iconColors?.color) {
      switch (this.message.type) {
        case ToastEventType.VALIDATION_TRUE: {
          return 'var(--color-correct-500)';
        }
        case ToastEventType.VALIDATION_FALSE: {
          return 'var(--color-wrong-500)';
        }
        case ToastEventType.INFO: {
          return 'var(--color-blurple-500)';
        }
        case ToastEventType.ERROR_ALERT: {
          return 'var(--color-wrong-500)';
        }
        default: {
          return 'var(--color-blurple-500)';
        }
      }
    }
    return this.message.iconColors.color;
  }

  public onDismissClick(): void {
    this.toastService.removeMessage(this.message.id);
  }
}
