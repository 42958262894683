import 'hammerjs';

import { PortalModule } from '@angular/cdk/portal';
import { Injectable, NgModule } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: { direction: Hammer.DIRECTION_ALL, threshold: 10, pointers: 1 },
    swipe: { direction: Hammer.DIRECTION_ALL, velocity: 0.1, threshold: 0 },
  };
}

@NgModule({
  imports: [PortalModule, HammerModule],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
  exports: [],
})
export class SharedModule {}
