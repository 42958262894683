import { DomPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';

import { DropdownService } from '../../services/dropdown.service';

/** The outlet for dropdowns.
 * This component is controlled by the dropdown service and relays global keypress
 * and focus events back to the service for further processing. */
@Component({
  imports: [],
  selector: 'app-dropdown-outlet',
  template: '',
  styleUrls: ['./dropdown-outlet.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOutletComponent implements AfterViewInit, OnDestroy {
  /** Subscribe to state of dropdown */
  private dropdownSubscription = this.dropdownService.open$.subscribe((open) => {
    if (open) {
      this.setUpFocusHandler();
    } else {
      this.destroyFocusHandler();
    }
  });

  /** @ignore */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private dropdownService: DropdownService,
    private elementRef: ElementRef,
  ) {}

  /** Set up a dom portal outlet and pass it on to the dropdown service */
  ngAfterViewInit(): void {
    const outlet = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.componentFactoryResolver,
      this.appRef,
      this.injector,
    );

    this.dropdownService.setPortalOutlet(outlet);
  }

  /** Add event listener that traps focus */
  private setUpFocusHandler(): void {
    // It would be nicer to use Angular host bindings for the event handling, but angular doesn't support capture instead of bubbling
    this.document.addEventListener('focus', this.focusHandler, true);
  }

  /** Remove event listener that traps focus */
  private destroyFocusHandler(): void {
    this.document.removeEventListener('focus', this.focusHandler, true);
  }

  /** Relays focus event on to the dropdown service. */
  private focusHandler = (event: FocusEvent): void => {
    this.dropdownService.handleFocus(event);
  };

  /** Unsubscribe from subscriptions when component is destroyed */
  ngOnDestroy(): void {
    this.dropdownSubscription.unsubscribe();
  }
}
