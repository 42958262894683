<app-button
  #button
  *ngIf="buttonType === DropDownButtonType.BASIC"
  [title]="buttonText"
  [variant]="variant"
  [active]="active"
  [disabled]="disabled"
  [loading]="loading"
  [appTooltip]="tooltip!"
  (click)="toggleDropDown()"
  tabindex="-1"
  ><div class="selected-container">
    <span>{{ buttonText }}</span>
    <app-icon [icon]="active ? Icons.CHEVRON_UP : Icons.CHEVRON_DOWN" [size]="IconSize.DEFAULT"></app-icon></div
></app-button>
<app-icon-button
  #button
  *ngIf="buttonType === DropDownButtonType.ICON"
  [disabled]="disabled"
  (click)="toggleDropDown()"
  [icon]="active ? buttonIconActive : buttonIcon"
  [iconSize]="IconSize.DEFAULT"
  [title]="buttonText"
  [appTooltip]="tooltip!"
></app-icon-button>
<div *ngIf="active" class="drop-down-wrapper" [ngStyle]="{ left: getLeftPosition }">
  <div class="modal-content" #dropDownContent>
    <ng-content></ng-content>
  </div>
</div>
