import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CoreService } from '@app/core/services/core.service';
import { NavbarItem } from '@app/page/interfaces/navbar.interface';
import { NavbarMapper } from '@app/page/mapper/navbar.mapper';
import { NavbarService } from '@app/page/services/navbar.service';
import { UserService } from '@app/user/services/user.service';
import { ModalComponent } from '@shared-components/modals/modal/modal.component';
import { ButtonVariant } from '@shared-components/ui/button/button.component';
import { PanelButtonComponent } from '@shared-components/ui/panel-button/panel-button.component';
import { DropDownButtonType } from '@shared-enums/drop-down-button-type.enum';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { BehaviorSubject } from 'rxjs';

export enum NavigationType {
  DEFAULT = 'topbar',
  TOPBAR = 'topbar',
  MEGAMENU = 'megamenu',
}
@Component({
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    RouterLink,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgTemplateOutlet,
    ModalComponent,
    PanelButtonComponent,
    TooltipDirective,
  ],
  selector: 'app-nav-menu-base',
  templateUrl: './nav-menu-base.component.html',
  styleUrls: ['./nav-menu-base.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuBaseComponent implements OnInit {
  @Input() navType?: NavigationType = NavigationType.DEFAULT;

  public NavigationType = NavigationType;
  public DropDownButtonType = DropDownButtonType;
  public ButtonVariant = ButtonVariant;

  public currentUrl: string = '';
  public embedVideo$ = new BehaviorSubject<NavbarItem | null>(null);

  constructor(
    public coreService: CoreService,
    public navbarService: NavbarService,
    private navbarMapper: NavbarMapper,
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.navbarService.currentUrl$.subscribe((data) => {
      this.currentUrl = data;
    });
  }

  isLinkActive(item: NavbarItem): boolean {
    return this.navbarMapper.isLinkActive(item, this.currentUrl);
  }

  isDropdownActive(items: NavbarItem[]): boolean {
    return this.navbarMapper.isDropdownActive(items);
  }

  onShowEmbedVideo(item: NavbarItem, event: Event): void {
    this.embedVideo$.next(item);
    (<HTMLElement>event.target).blur();
  }

  onClickEmbedVideoOverlay(): void {
    if (!this.embedVideo$.value) {
      return;
    }
    this.embedVideo$.next(null);
  }
}
