import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { tooltipAnimation } from '../../animations/tooltip.animation';
import { Tooltip } from '../../interfaces/tooltip.interface';
import { TooltipService } from '../../services/tooltip.service';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  imports: [NgFor, TooltipComponent, AsyncPipe],
  selector: 'app-tooltip-outlet',
  templateUrl: './tooltip-outlet.component.html',
  styleUrls: ['./tooltip-outlet.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [tooltipAnimation],
})
export class TooltipOutletComponent {
  constructor(public tooltipService: TooltipService) {}

  public trackByElementId(index: number, tooltip: Tooltip): string {
    return tooltip.elementId;
  }
}
