import { NgForOf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { addRandomNoiseToNumber, randomInRange, roundToStep } from '@utils/math';

interface SnowFlake {
  size: number;
  leftOffset: number;
  animationDurationVertical: number;
  animationDurationHorizontal: number;
  animationDelay: number;
}

@Component({
  selector: 'app-snowflakes',
  imports: [NgForOf],
  templateUrl: './snowflakes.component.html',
  styleUrls: ['./snowflakes.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnowflakesComponent implements OnChanges {
  @Input() count: number = 10;
  @Input() animationDurationVertical: number = 13;
  @Input() animationDurationHorizontal: number = 10;

  @HostBinding('attr.data-chromatic') dataChromatic = 'ignore';

  public snowflakes: SnowFlake[] = [];

  ngOnChanges(changes: SimpleChanges) {
    const percentageBetweenSnowflakes = 100 / this.count;
    this.snowflakes = [];

    for (let i = 0; i < this.count; i++) {
      this.snowflakes.push({
        size: roundToStep(randomInRange(4, 12), 4),
        leftOffset: randomInRange(i * percentageBetweenSnowflakes, (i + 1) * percentageBetweenSnowflakes),
        animationDurationVertical: addRandomNoiseToNumber(this.animationDurationVertical, 0.5),
        animationDurationHorizontal: addRandomNoiseToNumber(this.animationDurationHorizontal, 0.5),
        animationDelay: randomInRange(this.animationDurationVertical * -1, 0),
      });
    }
  }
}
