import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ManagedAssignmentItem } from '@app/assignments-managed/interfaces/managed-assignment-item.interface';
import { CartItem } from '@app/cart/interfaces/cart-item.interface';
import { ContentTypeIconComponent } from '@app/content/components/content-type-icon/content-type-icon.component';
import { ContentType } from '@app/content/enums/content-type.enum';
import { ContentTypeIconSize } from '@app/content/enums/content-type-icon-size.enum';
import { GradeLevelsModule } from '@app/grade-levels/grade-levels.module';
import { FormatGradeLevelSpanPipe } from '@app/grade-levels/pipes/format-grade-level-span.pipe';
import { GradeLevelsService } from '@app/grade-levels/services/grade-levels.service';
import { TranslatePipe } from '@ngx-translate/core';
import { Icons } from '@shared-data/icons';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';
import { PascalToKebabPipe } from '@shared-pipes/pascal-to-kebab.pipe';
import { ReadableArrayPipe } from '@shared-pipes/readable-array.pipe';
import { camelToKebab } from '@utils/string';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReadableArrayPipe,
    TooltipDirective,
    FormatGradeLevelSpanPipe,
    TranslatePipe,
    GradeLevelsModule,
    ContentTypeIconComponent,
    PascalToKebabPipe,
  ],
})
export class CartItemComponent {
  @Input() data!: CartItem | ManagedAssignmentItem;

  /**
   * Enables the settings area containing the elements passed as child elements in the parent template.
   */
  @Input() displaySettingsArea: boolean = false;

  protected readonly ContentTypeIconSize = ContentTypeIconSize;
  protected readonly ContentType = ContentType;
  protected readonly Icons = Icons;

  constructor(protected gradeLevelsService: GradeLevelsService) {}

  @HostBinding('class')
  public get contentTypeClassName(): string {
    return `content-type-${camelToKebab(this.data.contentType ?? '')}`;
  }
  public get hasMetaCount(): boolean {
    return [
      ContentType.EXERCISE_COLLECTION,
      ContentType.TRAINING_COURSE,
      ContentType.WORKSHEET,
      ContentType.ACTIVITY,
      ContentType.ADAPTIVE_TRAINING,
    ].includes(this.data.contentType);
  }
  public get metaCount(): number {
    switch (this.data.contentType) {
      case ContentType.EXERCISE_COLLECTION:
        return this.data.exerciseCount;
      case ContentType.WORKSHEET:
        return this.data.sheetCount;
      case ContentType.ACTIVITY:
        return this.data.estimatedDuration;
      case ContentType.ADAPTIVE_TRAINING:
        return this.data.exerciseCount;
    }

    return 0;
  }

  public get isAdaptive(): boolean {
    return this.data.contentType === ContentType.ADAPTIVE_TRAINING;
  }
}
