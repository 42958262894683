<ng-container *ngIf="showGraphics() || showPattern()">
  <div *ngIf="hasLoadedUserData()" class="background" [class]="userTypeTheme() + '-background'" @fadeInOut></div>

  <app-background-elements *ngIf="showGraphics()" [isTeacher]="isTeacher()"></app-background-elements>
</ng-container>

<ng-container *ngIf="isWinter() && !disableAnimations()">
  <app-snowflakes [count]="30"></app-snowflakes>

  <div class="snow-wrapper">
    <img class="snow-left" src="/assets/svg/backgrounds/winter/snow-left.svg" alt="" />
    <img class="snow-right" src="/assets/svg/backgrounds/winter/snow-right.svg" alt="" />
    <div class="snow-bottom"></div>
  </div>
</ng-container>
