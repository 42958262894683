import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { CoreService } from '@app/core/services/core.service';
import { getObservableValueSync } from '@app/utils/observables';
import { AzureAppConfigurationService } from '@core/services/azure-app-configuration.service';
import { filter, Subject, take } from 'rxjs';

@Component({
  imports: [NgIf, AsyncPipe],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  vm$ = new Subject<{ enabled: boolean; cssLoaded: boolean }>();
  enabled = false;
  cssLoaded = false;
  loading = false;
  loaded = false;
  data?: string;

  constructor(
    private router: Router,
    private coreService: CoreService,
    private azureAppConfigurationService: AzureAppConfigurationService,
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof RoutesRecognized),
        take(1),
      )
      .subscribe((event: any) => {
        this.handleLoadOrInjectFooter(event);
      });
  }

  async handleLoadOrInjectFooter(data: RoutesRecognized) {
    this.enabled = true;
    this.vmNext();
    if (!this.loading) {
      if (this.loaded) {
        await this.injectFooter();
      } else {
        this.loading = true;
        await this.loadFooter();
      }
    }
  }

  private async loadFooter(): Promise<void> {
    const portalId = getObservableValueSync<string>(this.coreService.portalId$);
    if (portalId !== undefined) {
      const url = `${this.azureAppConfigurationService.getSetting('apiUmbracoBaseUrl')}js/footer/?t=${portalId}`;
      await fetch(url)
        .then((response) => {
          return response.text();
        })
        .then(async (text) => {
          this.data = text.replace('{{id}}', portalId);
          await this.injectFooter();
        });
    }
  }

  private async injectFooter() {
    if (this.data !== undefined) {
      // The funky `(0, eval)` structure below has good reasons: https://esbuild.github.io/content-types/#direct-eval
      (await (0, eval)(this.data)) as Promise<any>;

      const footerHTMLCollection = document.getElementsByTagName('footer');
      if (footerHTMLCollection.length) {
        const interval = setInterval(() => {
          if (getComputedStyle(footerHTMLCollection[0], undefined).display === 'flex') {
            this.cssLoaded = true;
            this.loaded = true;
            this.loading = false;
            this.vmNext();
            clearInterval(interval);
          }
        }, 50);
      }
    }
  }

  private vmNext(): void {
    this.vm$.next({ enabled: this.enabled, cssLoaded: this.cssLoaded });
  }
}
