import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { fadeInOutAnimation } from '@shared-animations/fade-in-out.animation';
import { BackgroundElementsComponent } from '@shared-components/background-elements/background-elements.component';
import { SnowflakesComponent } from '@shared-components/snowflakes/snowflakes.component';

@Component({
  imports: [CommonModule, SnowflakesComponent, BackgroundElementsComponent],
  selector: 'app-background-graphics',
  templateUrl: './background-graphics.component.html',
  styleUrls: ['./background-graphics.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation],
})
export class BackgroundGraphicsComponent implements OnInit, OnDestroy {
  hasLoadedUserData = input<boolean>(false);
  isTeacher = input<boolean>(false);
  showGraphics = input<boolean>(false);
  showPattern = input<boolean>(false);
  isWinter = input<boolean>(false);
  disableAnimations = input<boolean>(false);
  inContainer = input<boolean>(false);
  backgroundColor = input<string>();
  backgroundImageUrl = input<string>();

  @HostBinding('class.in-container')
  get inContainerClass() {
    return this.inContainer();
  }

  protected userTypeTheme = computed(() =>
    this.hasLoadedUserData() ? (this.isTeacher() ? 'dotted' : 'square') : undefined,
  );

  protected backgroundColorFallback = computed(() => {
    switch (this.userTypeTheme()) {
      case 'dotted':
        return 'var(--color-blue-50)';
      case 'square':
        return 'var(--color-blurple-50)';
      default:
        return 'var(--color-blurple-50)';
    }
  });

  private pageBackgroundColor = computed(() => this.backgroundColor() || this.backgroundColorFallback());

  @HostBinding('style.--page-background-color')
  public get pageBackgroundColorStyle(): string {
    return this.pageBackgroundColor();
  }

  @HostBinding('style.--page-background-image-url')
  public get pageBackgroundImageUrl(): string {
    return this.backgroundImageUrl() ? `url(${this.backgroundImageUrl()})` : '';
  }

  @HostBinding('class.background-image')
  public get pageBackgroundImageProvided() {
    return this.backgroundImageUrl();
  }

  constructor() {
    effect(() => {
      document.body.style.backgroundColor = this.pageBackgroundColor();
    });
  }

  ngOnInit(): void {
    document.body.style.transition = 'background-color var(--transition-duration-medium-slow) ease-in-out';
  }

  ngOnDestroy(): void {
    document.body.style.transition = '';
  }
}
