import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OperationalNotification } from '@core/interfaces/umbraco/operational-notification.interface';
import { TranslatePipe } from '@ngx-translate/core';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { IconButtonComponent } from '@shared-components/ui/icon-button/icon-button.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';

@Component({
  selector: 'app-operational-notification',
  imports: [CommonModule, IconComponent, IconButtonComponent, TranslatePipe],
  templateUrl: './operational-notification.component.html',
  styleUrls: ['./operational-notification.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationalNotificationComponent {
  /**
   * The operational notification to display
   */
  @Input() operationalNotification?: OperationalNotification;
  /**
   * Enables the dismiss button
   */
  @Input() dismissible?: boolean;

  /**
   * Emits when the dismiss button is pressed
   */
  @Output() dismiss: EventEmitter<void> = new EventEmitter();

  protected readonly Icons = Icons;
  protected readonly IconSize = IconSize;

  protected onDismissClick() {
    this.dismiss.emit();
  }
}
