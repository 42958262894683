<ng-container
  *ngIf="{
    cartItemCount: (cartService.itemCount$ | async),
    logo: (coreService.logo$ | async)!,
    siteName: coreService.siteName$ | async,
    isLoggedIn: userService.isLoggedIn$ | async,
    hasAccess: userService.hasAccess$ | async,
    userInitialsShort: userService.userInitialsShort$ | async,
    userDisplayName: userService.userDisplayName$ | async,
    isTeacher: userService.isTeacher$ | async,
    searchAndAssign: navbarService.findContent$ | async,
    basketButtonDisabled: (basketButtonDisabled$ | async)!,
    globalTeamId: (teamsService.globalTeamId$ | async) ?? undefined
  } as sub"
>
  <div class="header-container">
    <app-panel-button
      #megaMenuDropdown
      class="mega-menu-container"
      [buttonType]="DropDownButtonType.ICON"
      [buttonIcon]="Icons.CHEVRON_DOWN"
      [buttonIconActive]="Icons.CLOSE"
      [buttonText]="'header.megaMenu' | translate"
    >
      <div class="mega-menu-container-content">
        <app-nav-menu-base class="navmenu-megamenu" [navType]="NavigationType.MEGAMENU"></app-nav-menu-base>
        <app-mega-menu></app-mega-menu>
      </div>
    </app-panel-button>
    <ng-container *ngIf="sub.logo || sub.siteName">
      <a class="product btn" routerLink="/" [title]="'app.navigation.go-to-frontpage' | translate">
        <span class="logo" *ngIf="sub.logo">
          <img *ngIf="sub.logo.mobile ?? sub.logo.default" [src]="sub.logo.mobile ?? sub.logo.default" alt="" />
        </span>
        <span *ngIf="sub.logo && sub.siteName" class="divider logo-divider"></span>
        <span *ngIf="sub.siteName" class="site-name">{{ sub.siteName }}</span>
      </a>
    </ng-container>

    <div class="flex-grow"></div>
    <app-team-selector-dropdown [selectedTeamId]="sub.globalTeamId" *ngIf="sub.isTeacher"></app-team-selector-dropdown>
    <app-nav-menu-base
      *ngIf="!sub.isLoggedIn || sub.hasAccess"
      class="navmenu-topbar mediumplus-up"
    ></app-nav-menu-base>
    <div *ngIf="sub.hasAccess" class="divider action-items-divider"></div>
    <div class="action-items">
      <app-icon-button
        *ngIf="sub.isTeacher && sub.searchAndAssign && sub.searchAndAssign.pageType && sub.searchAndAssign.url"
        [icon]="Icons.SEARCH"
        [iconSize]="IconSize.DEFAULT"
        [title]="'header.search-title' | translate"
        [iconButtonRouterLink]="sub.searchAndAssign.url"
        class="search-area search-menu-icon-button"
        [class.active-link]="isLinkActive(sub.searchAndAssign.url)"
        [attr.data-testid]="'header-' + sub.searchAndAssign.pageType + '-link'"
      ></app-icon-button>
      <app-dropdown
        [sameWidth]="false"
        [horizontalDirection]="'left'"
        [spacingFromButton]="17"
        [spacingFromWindow]="16"
        [width]="560"
        variant="header"
        [openFunc]="toggleOpen('cart')"
        [closeFunc]="toggleOpen('cart')"
        class="cart-area"
        [class.disabled]="sub.basketButtonDisabled"
      >
        <ng-container button>
          <app-icon-button
            [disabled]="sub.basketButtonDisabled"
            [badgeCount]="sub.cartItemCount ?? undefined"
            [hidden]="!sub.isTeacher"
            [icon]="cartMenuIcon"
            [iconSize]="IconSize.DEFAULT"
            class="cart-menu-icon-button"
          ></app-icon-button>
        </ng-container>
        <ng-container>
          <app-cart (dismiss)="onCartDismissClick()"></app-cart>
        </ng-container>
      </app-dropdown>

      <app-button *ngIf="!sub.isLoggedIn" class="login-button" [variant]="ButtonVariant.WHITE" (click)="onLogin()">{{
        'app.navigation.log-in' | translate
      }}</app-button>
      <app-dropdown
        *ngIf="sub.isLoggedIn"
        [sameWidth]="false"
        [horizontalDirection]="'left'"
        [spacingFromButton]="10"
        [spacingFromWindow]="10"
        [openFunc]="toggleOpen('user')"
        [closeFunc]="toggleOpen('user')"
        class="user-area"
      >
        <ng-container button>
          <app-button
            class="user-area-button"
            [title]="sub.userDisplayName || ('header.your-profile-button' | translate)"
          >
            <app-icon *ngIf="!sub.userInitialsShort" [icon]="Icons.USER" [size]="IconSize.DEFAULT"></app-icon>
            {{ sub.userInitialsShort }}
          </app-button>
        </ng-container>
        <ng-container>
          <app-user></app-user>
        </ng-container>
      </app-dropdown>
    </div>
  </div>
</ng-container>
