import { NgIf, NgStyle } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Icons } from '@shared-data/icons';
import { DropDownButtonType } from '@shared-enums/drop-down-button-type.enum';
import { IconSize } from '@shared-enums/icon-size.enum';
import { Icon } from '@shared-interfaces/icon.interface';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';

import { ButtonComponent, ButtonVariant } from '../button/button.component';
import { IconComponent } from '../icon/icon.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
@Component({
  imports: [NgIf, NgStyle, IconComponent, ButtonComponent, IconButtonComponent, TooltipDirective],
  selector: 'app-panel-button',
  templateUrl: './panel-button.component.html',
  styleUrls: ['./panel-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelButtonComponent {
  @Input() public buttonText?: string = '';
  @Input() public leftPosition?: 'unset' | number = 0;
  @Input() public buttonType: DropDownButtonType = DropDownButtonType.SORT;
  @Input() public active: boolean = false;
  @Input() public variant: ButtonVariant = ButtonVariant.PRIMARY;
  @Input() public disabled: boolean = false;
  @Input() public buttonIcon?: Icon;
  @Input() public buttonIconActive?: Icon;
  @Input() public tooltip?: string | TemplateRef<any> = '';
  @Input() loading: boolean = false;
  @Output() public activeState = new EventEmitter<boolean>();
  @ViewChild('dropDownContent') private dropDownContent: ElementRef | undefined;
  @ViewChild('button', { read: ElementRef }) private button: ElementRef | undefined;
  public isActive: boolean = false;

  @HostListener('document:pointerdown', ['$event'])
  onGlobalClick(event: Event): void {
    if (this.button?.nativeElement) {
      if (!this.button?.nativeElement.contains(event.target)) {
        if (!this.dropDownContent?.nativeElement?.contains(event.target)) {
          this.active = false;
          this.activeState.emit(this.active);
        }
      }
    }
  }

  public DropDownButtonType = DropDownButtonType;
  public ButtonVariant = ButtonVariant;
  public Icons = Icons;
  public IconSize = IconSize;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}
  public toggleDropDown(active?: boolean): void {
    this.active = active !== undefined ? active : !this.active;
    this.activeState.emit(this.active);

    // Change detection hangs when calling `toggleDropDown` from another component, so we have to give it a little push
    this.changeDetectorRef.markForCheck();
  }
  get getLeftPosition(): string {
    return this.leftPosition !== 'unset' ? `${this.leftPosition}px` : this.leftPosition;
  }
}
