import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';

/**
 * A button that hides at the top of the page and only becomes visible when focused by tabbing to it using the keyboard.
 * Jumps to the given content ID when activated.
 * Should always be the first interactive element on the page when used.
 */
@Component({
  imports: [TranslatePipe],
  selector: 'app-skip-to-content-button',
  templateUrl: './skip-to-content-button.component.html',
  styleUrls: ['./skip-to-content-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkipToContentButtonComponent {
  @Input() contentId?: string;

  constructor(private router: Router) {}

  get href(): string {
    return `${this.router.url.split('#')[0]}#${this.contentId}`;
  }

  public onKeydown(event: KeyboardEvent): void {
    switch (event.code) {
      case 'Space':
      case 'Enter':
        event.preventDefault();
        this.focusOnContent();
    }
  }

  public onClick(event: Event): void {
    event.preventDefault();
    this.focusOnContent();
  }

  private focusOnContent(): void {
    const element = document.getElementById(this.contentId ?? 'main');

    if (element) {
      if (!element.hasAttribute('tabindex')) {
        element.setAttribute('tabindex', ' -1');
      }

      element.scrollIntoView({ behavior: 'smooth' });
      element.focus();
    }
  }
}
