import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Breadcrumb } from '@app/components/breadcrumb/interfaces/breadcrumb.interface';
import { TranslatePipe } from '@ngx-translate/core';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { TooltipDirective } from '@shared-modules/tooltip/directives/tooltip.directive';

@Component({
  selector: 'app-breadcrumb',
  imports: [CommonModule, TranslatePipe, IconComponent, TooltipDirective, RouterLink],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input() breadcrumbs: Breadcrumb[] = [];

  protected readonly Icons = Icons;
  protected readonly IconSize = IconSize;
}
