import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreService } from '@app/core/services/core.service';
import { MegaMenu, MegaMenuTab } from '@app/page/interfaces/mega-menu.interface';
import { IconComponent } from '@shared-components/ui/icon/icon.component';
import { Icons } from '@shared-data/icons';
import { IconSize } from '@shared-enums/icon-size.enum';
import { BackgroundImagePipe } from '@shared-pipes/background-image.pipe';
import { UmbracoImagePipe } from '@shared-pipes/umbraco-image.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  imports: [NgIf, NgFor, AsyncPipe, NgTemplateOutlet, IconComponent, UmbracoImagePipe, BackgroundImagePipe],
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegaMenuComponent implements OnInit {
  private _megaMenu: MegaMenu | undefined;
  public megaMenu$: BehaviorSubject<MegaMenu | undefined> = new BehaviorSubject<MegaMenu | undefined>(undefined);
  Icons = Icons;
  IconSize = IconSize;
  constructor(private coreService: CoreService) {}

  ngOnInit(): void {
    this.coreService.megaMenu$.subscribe((data) => {
      this._megaMenu = data;
      this.megaMenu$.next(this._megaMenu);
    });
  }

  changeTab(tab: MegaMenuTab) {
    if (this._megaMenu) {
      this._megaMenu = { ...this._megaMenu, currentTab: tab };
      if (this._megaMenu.activelyOpenedTab === tab) {
        this._megaMenu.activelyOpenedTab = undefined;
      } else {
        this._megaMenu.activelyOpenedTab = tab;
      }
      this.megaMenu$.next(this._megaMenu);
    }
  }
}
